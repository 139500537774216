export var ACTIONS;
(function (ACTIONS) {
    ACTIONS["UPDATE_SIGN_IN_TIME"] = "UPDATE_SIGN_IN_TIME";
    ACTIONS["SAVE_STATE"] = "SAVE_STATE";
    ACTIONS["CLEAR_SAVED_STATE"] = "CLEAR_SAVED_STATE";
    ACTIONS["CHECK_IF_USER_IS_ADMIN"] = "CHECK_IF_USER_IS_ADMIN";
    ACTIONS["SUBSCRIBE_TO_AUTH_STATE_CHANGE"] = "SUBSCRIBE_TO_AUTH_STATE_CHANGE";
    ACTIONS["SUBSCRIBE_TO_USER_WORKSPACES"] = "SUBSCRIBE_TO_USER_WORKSPACES";
    ACTIONS["UNSUBSCRIBE_FROM_USER_WORKSPACES"] = "SUBSCRIBE_TO_USER_WORKSPACES";
    ACTIONS["UPLOAD_VIDEO_FILE"] = "UPLOAD_VIDEO_FILE";
    ACTIONS["CANCEL_FILE_UPLOAD"] = "CANCEL_FILE_UPLOAD";
    ACTIONS["REFRESH_AUTH_COOKIE"] = "REFRESH_AUTH_COOKIE";
    ACTIONS["SIGN_OUT"] = "SIGN_OUT";
    ACTIONS["CLEAR_AUTH_COOKIE"] = "CLEAR_AUTH_COOKIE";
    ACTIONS["GET_TOTAL_EMOJI_CLICKS"] = "GET_TOTAL_EMOJI_CLICKS";
    ACTIONS["GET_FLY_OUT_EMOJIS"] = "GET_FLY_OUT_EMOJIS";
    ACTIONS["SET_CUSTOM_CLAIMS"] = "SET_CUSTOM_CLAIMS";
    ACTIONS["DELETE_VIDEO_ONERROR"] = "DELETE_VIDEO_ONERROR";
    ACTIONS["BATCH_UPLOAD_VIDEO_FILES"] = "BATCH_UPLOAD_VIDEO_FILES";
    ACTIONS["GET_HEATMAP_DATA"] = "GET_HEATMAP_DATA";
    ACTIONS["GET_SHARE_LINK_WORKSPACE"] = "GET_SHARE_LINK_WORKSPACE";
    ACTIONS["SET_LINK"] = "SET_LINK";
    ACTIONS["ADD_RECENT_WORKSPACE"] = "ADD_RECENT_WORKSPACE";
    ACTIONS["UPDATE_USER_RECORD"] = "UPDATE_USER_RECORD";
    ACTIONS["DELETE_RECENT_WORKSPACE"] = "DELETE_RECENT_WORKSPACE";
    ACTIONS["CLEANUP_RECENT_WORKSPACES"] = "CLEANUP_RECENT_WORKSPACES";
    ACTIONS["SUBSCRIBE_TO_USER_DOC_CHANGES"] = "SUBSCRIBE_TO_USER_DOC_CHANGES";
})(ACTIONS || (ACTIONS = {}));
export default ACTIONS;
