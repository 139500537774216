import { Role, TeamRole } from '@lumiere/db/types'

export enum AbilityModels {
  USER = 'User',
  WORKSPACE = 'Workspace',
  WORKSPACE_EMOJI = 'Workspace.Emoji',
  VIDEO = 'Video',
  VIDEO_FEATURE = 'Video.Feature',
  CHANNEL = 'Channel',
  LINK = 'Link',
  TEAM = 'Team',
  VIDEO_PERSON = 'Video.Person',
}

export const LumiereModels = Object.values(AbilityModels)

export const LumiereRoles = Object.values(Role)

export const LumiereTeamRoles = Object.values(TeamRole)
