import Layout from '@/components/Layout.vue';
import WorkspacesSelectDialog from '@/components/WorkspacesSelectDialog.vue';
import CreateWorkspaceForm from '@/components/CreateWorkspaceForm.vue';
import NavDrawer from '@/components/workspace/NavDrawer.vue';
import WorkspaceSelect from '@/components/workspace/WorkspaceSelect.vue';
import UploadProgressCards from '@/components/workspace/videos/UploadProgressCards.vue';
import DropZone from '@/components/workspace/videos/DropZone.vue';
import ShareLinkPasswordBlocker from '@/components/ShareLinkPasswordBlocker.vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import { Routes } from '@/router/router';
import ACTIONS from '@/store/ACTIONS';
import mixins from 'vue-typed-mixins';
import ThemeColor from '@/utils/ThemeColor';
export default mixins(ThemeColor).extend({
    props: {
        workspaceId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            teamId: null,
            showNavDrawer: false,
            listWorkspacesDialog: false,
            addWorkspaceDialog: false,
            workspacesSelectDialogSelectedWorkspaceId: null,
        };
    },
    computed: {
        ...mapState(['workspaces', 'link']),
        ...mapGetters([
            'currentWorkspace',
            'isAuthenticated',
            'userRoleInWorkspace',
        ]),
        currentWorkspaceID() {
            return this.currentWorkspace ? this.currentWorkspace.id : null;
        },
        content: () => [
            // { label: 'Workspace', route: Routes.Landing },
            { label: 'Videos', route: Routes.Videos },
            {
                label: 'Channels',
                route: Routes.Channels,
            },
        ],
        Routes() {
            return Routes;
        },
        isPublic() {
            return !!this.$route.query.link;
        },
        shouldShowNavbar() {
            if (this.userRoleInWorkspace || !this.isPublic) {
                return true;
            }
            return false;
        },
        shouldLockShareLink() {
            if (this.userRoleInWorkspace) {
                return false;
            }
            if (!this.isPublic)
                return false;
            if (!this.link)
                return true;
            const CURRENT_PASSWORD_CACHE = JSON.parse(sessionStorage.getItem('SHARE_LINK_CACHE') ?? '{}');
            const existing_entry = CURRENT_PASSWORD_CACHE[this.link.id];
            const now = Date.now();
            if (existing_entry > now - 3600 * 1000) {
                return false;
            }
            const linkPassword = this.link.configuration?.pageOptions?.password;
            return !!linkPassword?.length;
        },
    },
    methods: {
        ...mapActions({
            addRecentWorkspace: ACTIONS.ADD_RECENT_WORKSPACE,
        }),
        workspaceCreated(wid) {
            this.addWorkspaceDialog = false;
            void this.$router.push(`/${wid}/videos`);
        },
        showWorkspacesListDialog() {
            this.listWorkspacesDialog = true;
        },
        showAddWorkspaceDialog(teamId) {
            this.teamId = teamId ?? null;
            this.addWorkspaceDialog = true;
            this.listWorkspacesDialog = false;
        },
    },
    watch: {
        currentWorkspace: {
            immediate: true,
            handler(currentWorkspace) {
                if (currentWorkspace && !currentWorkspace.deleted) {
                    this.addRecentWorkspace(currentWorkspace.id);
                }
                else {
                    this.$router.replace({ name: Routes.Landing });
                }
            },
        },
    },
    components: {
        Layout,
        WorkspaceSelect,
        UploadProgressCards,
        NavDrawer,
        WorkspacesSelectDialog,
        CreateWorkspaceForm,
        DropZone,
        ShareLinkPasswordBlocker,
    },
});
