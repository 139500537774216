import { AbilityBuilder, Ability } from '@casl/ability'
import { Workspace, Team, CustomClaims } from '@lumiere/db/types'
import {
  defineAbilityForOwner,
  defineAbilityForEditor,
  defineAbilityForReviewer,
  defineAbilityForViewer,
  defineAbilityForTeamAdmin,
} from '../ability'
import { getCurrentUser, User as UserType } from '../services/firebase/auth'

export default async function clientAbilities(
  workspace?: Workspace | null,
  team?: Team | null,
): Promise<any[]> {
  const { can, rules, cannot } = new AbilityBuilder(Ability)
  const user: UserType | null = await getCurrentUser()
  // uncomment the console.log to have a sense of the output
  // console.log('current user', { user, workspace })

  if (workspace) {
    can('read', 'Workspace')
  }

  if (user) {
    const userClaims = (await user.getIdTokenResult())?.claims
    const hasClaim = (claim: string) => {
      return userClaims[claim] === true
    }
    const isAdmin = hasClaim(CustomClaims.ADMIN)
    const isUser = hasClaim(CustomClaims.USER)
    const isDeveloper = hasClaim(CustomClaims.DEVELOPER)
    const isAnonymousUser = user.isAnonymous

    // admin has read-write access to everything
    if (isAdmin || isDeveloper) {
      can('manage', 'all')
    }

    // TODO: give anonymous users the user claim
    if (isUser || isAnonymousUser) {
      can('read', [
        'Video.File',
        'Video.Feature',
        'Workspace.Emoji',
        'Link',
        'Video.Person',
      ]) // user has read-only access to the defined models
      can('create', 'Workspace') // all authenticated users can create a workspace
      can(['create', 'update'], 'Elasticsearch.Data') // all users can create & update elasticsearch data using the on-video features
      can('read', 'Elasticsearch.BasicAnalyticsData')
    }

    if (isUser) {
      // when in the context of workspace
      if (workspace) {
        defineAbilityForOwner(user.uid, workspace, hasClaim)(can, cannot)
        defineAbilityForEditor(user.uid, workspace, hasClaim)(can, cannot)
        defineAbilityForReviewer(user.uid, workspace, hasClaim)(can, cannot)
        defineAbilityForViewer(user.uid, workspace, hasClaim)(can, cannot)
      }

      // when in the context of team
      const theTeam = team || workspace?.team
      if (theTeam) {
        const isTeamMember = theTeam.roles[user.uid]

        if (isTeamMember) {
          can('read', 'Team')

          defineAbilityForTeamAdmin(user.uid, theTeam, hasClaim)(can, cannot)
        }
      }
    }
  }
  return rules
}
