import storage from 'local-storage-fallback'

function locallyStored<T = string | object>(KEY: string) {
  return {
    get(): T | undefined {
      const locallyStoredItem = storage.getItem(KEY)
      if (locallyStoredItem) {
        try {
          const { value } = JSON.parse(locallyStoredItem)
          return value as T
        } catch {
          return (locallyStoredItem as unknown) as T
        }
      }
    },
    set(value: T): void {
      storage.setItem(KEY, JSON.stringify({ value }))
    },
    remove: () => {
      storage.removeItem(KEY)
    },
  }
}

export default locallyStored
