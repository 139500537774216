import { paramCase } from 'change-case'
import colors, { Color } from 'vuetify/lib/util/colors'
import randomInt from 'random-int'

const COLORS: { [colorName: string]: string } = Object.keys(colors)
  .filter((v) => v !== 'shades')
  .reduce(
    (acc, cur) => ({
      [paramCase(cur)]: ((colors as any)[cur] as Color).base,
      ...acc,
    }),
    {},
  )

export const getRandomColor = (): string => {
  const colors = Object.keys(COLORS)
  const colorIdx = randomInt(0, colors.length - 1)
  return colors[colorIdx]
}

//Get the hex color from a color code.
//If the color is already a CSS color code, then just return it.
export const getHexColor = (colorKey: string | null | undefined) => {
  return !colorKey
    ? '#666'
    : colorKey.match(/^#([0-9A-Fa-f]+)$/)
    ? colorKey
    : COLORS[colorKey]
}

export default COLORS
