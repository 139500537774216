import { Id, Created, Edited, Deleted } from './db.base.types'
import { LinkStatus } from './db.links.types'

export type ChannelVideoWidget =
  | {
      type: 'TOTAL_VIEWS'
    }
  | {
      type: 'TOP_EMOJI'
    }
  | {
      type: 'RELATED_VIDEOS'
      options: {
        count: number
      }
    }

export type ChannelWidget =
  | {
      type: 'TOP_VIDEOS'
      options: {
        count: number
      }
    }
  | {
      type: 'TOP_MOMENTS'
      options: {
        count: number
      }
    }
  | {
      type: 'RECENT_COMMENTS'
      options: {
        count: number
      }
    }

type tagId = string

export interface ChannelTag {
  color: string
  label: string
  nonce: number
}

export enum ChannelTileSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export interface ChannelProps extends Partial<Deleted> {
  title: string
  description?: string
  workspaceId: string
  image?: string
  color: string
  channelTags: Record<tagId, ChannelTag>
  videos: string[]
  channelVideoTags: {
    [videoId: string]: tagId[]
  }
  options: {
    showSearch: boolean
    showTagNavigation: boolean
    groupByTag: boolean
    shuffleVideos: boolean
    shuffleGroups: boolean
    showTitle: boolean
    showDuration: boolean
    showDescription: boolean
    showTags: boolean
    tileSize: ChannelTileSize
    css?: string
  }
  channelWidgets: ChannelWidget[] // ordered array of side widgets
  videoWidgets: ChannelVideoWidget[] // ordered array of side widgets on video page
  eids?: string[]
  visibility: ChannelVisibility
  shareLink?: ChannelShareLink
  status: ChannelStatus
}

export enum ChannelVisibility {
  Public = 'Public',
  Private = 'Private',
}

export interface ChannelShareLink {
  id: string
  path: string
  fullPath: string
  status: LinkStatus
}

export enum ChannelStatus {
  Active = 'Active',
  Deleted = 'Deleted',
}

export interface Channel extends Id, ChannelProps, Created, Edited {}
