export var AssetType;
(function (AssetType) {
    AssetType["video"] = "video";
    AssetType["thumbnail"] = "thumbnail";
    AssetType["gif"] = "gif";
    AssetType["storyboard"] = "storyboard";
})(AssetType || (AssetType = {}));
export var VideoStatus;
(function (VideoStatus) {
    VideoStatus["Uploading"] = "Uploading";
    VideoStatus["Processing"] = "Processing";
    VideoStatus["Ready"] = "Ready";
    VideoStatus["Deleted"] = "Deleted";
    VideoStatus["Errored"] = "Errored";
})(VideoStatus || (VideoStatus = {}));
export var VideoVisibility;
(function (VideoVisibility) {
    VideoVisibility["Public"] = "Public";
    VideoVisibility["Private"] = "Private";
})(VideoVisibility || (VideoVisibility = {}));
export var VideoAIJobStatus;
(function (VideoAIJobStatus) {
    VideoAIJobStatus["UPLOADING"] = "Uploading";
    VideoAIJobStatus["UPLOADED"] = "Uploaded";
    VideoAIJobStatus["PROCESSING"] = "Processing";
    VideoAIJobStatus["PROCESSED"] = "Processed";
    VideoAIJobStatus["FAILED"] = "Failed";
    VideoAIJobStatus["QUARANTINED"] = "Quarantined";
})(VideoAIJobStatus || (VideoAIJobStatus = {}));
export var VideoAIExternalStatus;
(function (VideoAIExternalStatus) {
    VideoAIExternalStatus["INGESTING"] = "Ingesting";
    VideoAIExternalStatus["INGESTED"] = "Ingested";
})(VideoAIExternalStatus || (VideoAIExternalStatus = {}));
export var EndAction;
(function (EndAction) {
    EndAction["PauseOnLastFrame"] = "PauseOnLastFrame";
    EndAction["ResetToThumbnail"] = "ResetToThumbnail";
    EndAction["Loop"] = "Loop";
    EndAction["ShowEndScreen"] = "ShowEndScreen";
})(EndAction || (EndAction = {}));
export var VideoLogoPosition;
(function (VideoLogoPosition) {
    VideoLogoPosition["TopLeft"] = "TopLeft";
    VideoLogoPosition["TopRight"] = "TopRight";
    VideoLogoPosition["BottomLeft"] = "BottomLeft";
    VideoLogoPosition["BottomRight"] = "BottomRight";
})(VideoLogoPosition || (VideoLogoPosition = {}));
export const DefaultVideoBehaviors = {
    autoplay: false,
    allowScrubbing: true,
    pauseOnBlur: false,
    onEnd: {
        action: EndAction.ResetToThumbnail,
    },
};
