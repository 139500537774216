import {
  CornerPosition,
  DefineFeature,
  DefineFeatureProps,
  FeatureType,
  VideoTimeFeatureTrigger,
} from './db.video.features.types'

export interface CommentFeedbackOptions {
  shouldPauseVideo: boolean
  alwaysExpanded: boolean
  spacialTagging: boolean
  spacialTaggingRequired: boolean
  hidePromptWhenSpacialTaggingEnabled?: boolean
}

export interface FeatureOptionsComment {
  prompt?: string
  position: CornerPosition
  feedbackOptions: CommentFeedbackOptions
}

export enum CommentPlusFeature {
  Ratings = 'ratings',
  Stars = 'stars',
  Emojis = 'emojis',
  Tags = 'tags',
}

export interface PlusFeatureRatings {
  type: CommentPlusFeature.Ratings
  prompt: string
  required: boolean
  labels: {
    min: string
    max: string
  }
}

export interface PlusFeatureRatingsStars {
  type: CommentPlusFeature.Stars
  prompt: string
  required: boolean
  count: number
}

export interface PlusFeatureEmojis {
  type: CommentPlusFeature.Emojis
  prompt: string
  required: boolean
  emojiId: string
}

export interface PlusFeatureTags {
  type: CommentPlusFeature.Tags
  prompt: string
  required: boolean
  tags: string[]
}

export type PlusFeatureType =
  | PlusFeatureRatings
  | PlusFeatureRatingsStars
  | PlusFeatureEmojis
  | PlusFeatureTags

export interface FeatureOptionsCommentPlus extends FeatureOptionsComment {
  plus: PlusFeatureType | null
}

export type CommentFeatureProps = DefineFeatureProps<
  FeatureType.Comments,
  FeatureOptionsCommentPlus,
  VideoTimeFeatureTrigger,
  VideoTimeFeatureTrigger
>

export type CommentFeature = DefineFeature<CommentFeatureProps>
