import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { collectionData } from 'rxfire/firestore';
export const splitBy = (n) => function (arr) {
    const response = [];
    for (let i = 0; i < arr.length; i = i += n) {
        response.push(arr.slice(i, i + n));
    }
    return response;
};
function createdSignature(uid, FieldValue) {
    return {
        createdBy: uid,
        createdTime: FieldValue.serverTimestamp(),
        ...editedSignature(uid, FieldValue),
    };
}
function editedSignature(uid, FieldValue) {
    return {
        editedBy: uid,
        editedTime: FieldValue.serverTimestamp(),
    };
}
function deletedSignature(uid, FieldValue) {
    return {
        deleted: true,
        deletedBy: uid,
        deletedTime: FieldValue.serverTimestamp(),
    };
}
export const signatures = (FieldValue) => ({
    edited: (uid) => editedSignature(uid, FieldValue),
    created: (uid) => createdSignature(uid, FieldValue),
    deleted: (uid) => deletedSignature(uid, FieldValue),
});
export const dbCollection = (path) => ({ firestore, dbAuth, FieldPath, }) => {
    const ref = firestore.collection(path);
    return (query) => ({
        observe: () => from(getUID(dbAuth)).pipe(switchMap((uid) => collectionData(query ? query(ref, { uid, FieldPath }) : ref, 'id'))),
        get: async () => {
            const result = await (query
                ? query(ref, { uid: await getUID(dbAuth), FieldPath })
                : ref).get();
            return mapDocs(result);
        },
        update: async (update, originalBatch) => {
            const batch = originalBatch ?? firestore.batch();
            const docs = await (query
                ? query(ref, { uid: await getUID(dbAuth), FieldPath })
                : ref).get();
            for (const doc of docs.docs) {
                batch ? batch.update(doc.ref, update) : await doc.ref.update(update);
            }
            if (!originalBatch)
                return batch.commit();
        },
        delete: async (outerBatch) => {
            const batch = outerBatch || firestore.batch();
            const docs = await (query
                ? query(ref, { uid: await getUID(dbAuth), FieldPath })
                : ref).get();
            docs.forEach((docSnapshot) => {
                batch.delete(docSnapshot.ref);
            });
            if (!outerBatch) {
                await batch.commit();
            }
        },
    });
};
export function mapDoc(doc) {
    if (doc.exists)
        return { id: doc.id, ...doc.data() };
    return null;
}
export function mapDocs(snapshot) {
    return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
}
export async function getUID(dbAuth) {
    const uid = await dbAuth.uid();
    if (!uid) {
        throw new Error('Expected user to be authenticated');
    }
    return uid;
}
export const observe = (ref) => () => new Observable((sub) => ref.onSnapshot((snapshot) => snapshot.exists &&
    sub.next(Object.assign({ id: snapshot.id }, snapshot.data()))));
